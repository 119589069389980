const qs = require("qs")
const moment = require("moment")

const cisSelector = $('#cis-selector')
const periodSelector = $('#period-selector')
const uisSelector = $('#uis-selector')

const queryParams = qs.parse(window.location.search?.replace('?', '')) || {}
if (queryParams.cis) cisSelector.val(queryParams.cis)
if (queryParams.uis) uisSelector.val(queryParams.uis)
if (queryParams.period) periodSelector.val(queryParams.period)
if (queryParams.from && queryParams.to) {
  $('#datepicker-from').val(queryParams.from)
  $('#datepicker-to').val(queryParams.to)
}

function setQueryParams(params) {
  const queryParams = qs.parse(window.location.search?.replace('?', '')) || {}
  const newQuery = qs.stringify({ ...queryParams, ...params }, { arrayFormat: 'brackets' })
  window.history.replaceState(null, null, `?${newQuery}`)
}

function updateDatepicker(from, to) {
  $('#datepicker-from').datepicker('update', from?.toDate())
  $('#datepicker-to').datepicker('update', to?.toDate())
  setQueryParams({ from: from?.format('DD.MM.YYYY'), to: to?.format('DD.MM.YYYY')})
}

cisSelector.change(() => {
  setQueryParams({ cis: cisSelector.val() })
})

uisSelector.change(() => {
  setQueryParams({ uis: uisSelector.val() })
})

periodSelector.change(() => {
  const now = moment()
  switch (periodSelector.val()) {
    case 'day':
      updateDatepicker(moment(now).startOf('day'), now)
      break

    case 'month':
      updateDatepicker(moment(now).startOf('month'), now)
      break

    case 'quarter':
      updateDatepicker(moment(now).startOf('quarter'), now)
      break

    case 'semester':
      updateDatepicker(moment([now.year(), now.month() < 6 ? 0 : 6]), now)
      break

    case 'year':
      updateDatepicker(moment(now).startOf('year'), now)
      break

    case 'all':
      updateDatepicker()
  }
  setQueryParams({ period: periodSelector.val() })
})

$('.input-daterange').on('changeDate', (e) => {
  const date = moment(e.date).format('DD.MM.YYYY')
  if (e.target.id === 'datepicker-from') {
    setQueryParams({ from: date})
  } else if (e.target.id === 'datepicker-to') {
    setQueryParams({ to: date })
  }
  setQueryParams({ period: 'custom' })
})

$('#accept-filters').click(() => {
  document.location.reload()
})

document.addEventListener('turbolinks:load', () => {
  setQueryParams({ from: $('#datepicker-from').val(), to: $('#datepicker-to').val() })
})
