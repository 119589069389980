document.addEventListener('turbolinks:load', () => {
  $('#uis-delete-button').click(function() {
    let answer = confirm('Удалить выбранные ЕИС?')

    if (answer) {
      $.get('/uni_systems/bulk_destroy', { "uis_ids[]": getUisIds() }).done(function() {
        location.reload()
      })
    }
  })

  $('#uis-card-show-signer-params').click(function() {
    if( $('#signer-parameters-is-shown').val() == 'false' ) {
      $('#signer-parameters').slideDown(1000);
      $('#signer-parameters-is-shown').val('true');
      $('#uis-card-show-signer-params').text('Скрыть параметры подключения к Signer');
    }
    else {
      $('#signer-parameters').slideUp(1000);
      $('#signer-parameters-is-shown').val('false');
      $('#uis-card-show-signer-params').text('Показать параметры подключения к Signer');
    }
  })

  $('.uis-delete-checkbox').change(function() {
    let ids = getUisIds()
    if (ids.length > 0) {
      $('#uis-delete-button').removeClass('disabled')
    } else {
      $('#uis-delete-button').addClass('disabled')
    }
  })

  function getUisIds() {
    const uis_ids = []
    $('.uis-delete-checkbox:checked').each(function() {
      uis_ids.push(this.value)
    })
    return uis_ids
  }

  $('#crypto-mode-select').change(function() {
    if ($('#crypto-mode-select').val() === "remote") {
      $('#signer-parameters-remote').slideDown(1000);
      $('#signer-parameters-local').slideUp(1000);
    } else {
      $('#signer-parameters-remote').slideUp(1000);
      $('#signer-parameters-local').slideDown(1000);
    }
  })
})
